import { LOCALES } from '../locales'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [LOCALES.ENGLISH]: {
    //COMMON
    commonBackToTheMainPage: 'Back to the main page',
    hideRows: 'First 10 rows were hidden',

    //HEADER
    headerDevicesConnected: 'Connected to panel',
    headerOptToUsers: 'Set user otp',
    headerOpenProfiles: 'Open profiles',
    headerOpenDocuments: 'Open documents',
    headerTotalMoney: 'Total money',
    headerTotalFee: 'Total fee',

    // SEND OTP MODAL
    otpCode: 'User otp code',
    setOtp: 'Set otp',
    otpSetSuccess: 'Otp code mocked for user for next 60 minutes and code copied to your clipboard',
    otpSetFailed: 'Otp code failed',
    
    //DEVICES MODAL
    devicesModalTitle: 'Active Devices',
    otpModalTitle: 'Set user otp',
    robotCreditsModalTitle: 'Change user robot sms credits',
    devicesModalActionDisconnectSuccess: 'Device disconnect success',
    devicesModalActionDisconnectFailure: 'Device disconnect error',

    //ADD PAYMENT MODAL
    apModalTitle: 'Add new payment',
    apModalDropFileHere: 'Drop file here',
    apModalDragFileHere: 'Drag file here',
    apModalUploadFile: 'UPLOAD FILE',
    apModalReUploadFile: 'REUPLOAD FILE',

    //FILTER
    filterTitle: 'FILTER',
    filterItemOnlyPaid: 'Only paid',
    filterItemSortByName: 'Sort by name',
    filterItemAscending: 'Ascending',
    filterSubmit: 'SUBMIT',
    filterRestore: 'RESTORE',
    filterSearch: 'SEARCH',

    //CUSTOM DATE RANGE PICKER
    cdrpFrom: 'From...',
    cdrpTo: 'To...',

    //CUSTOM DATAGRID
    customDataGridLoadMore: 'LOAD MORE',

    // EVENTS STATUS NAV
    esnWaitingForConfirm: 'Waiting for confirm',
    esnDeletedEvents: 'Deleted',
    esnFinishedEvents: 'Finished',
    esnHiddenEvents: 'Hidden',
    esnAllEvents: 'All',
    esnActiveEvents: 'Active',
    esnCanceledEvents: 'Canceled',
    esnInProgressEvents: 'In Progress',
    esnCurrentWeekEvents: 'Current week',

    //PROFILES
    gridProfilesTotalClearances: 'Total clearances',
    gridProfilesTotalPurchases: 'Total purchases',
    gridProfilesAmountOfPurchasedTickets: 'Amount of tickets purchased',
    gridProfilesAmountOfEvents: 'Amount of events',
    gridProfilesProducerAndIdentityCardOrHPAndPhoneNumber:
      'Producer information',
    gridProfilesCreatorOfTheEvent: 'Creator of the event',
    gridProfilesPostalCode: 'Postal code',
    gridProfilesCellphoneNumber: 'Cellphone number',
    gridProfilesLinkToProfile: 'Link to profile',
    gridProfilesDeletedUser: 'Deleted user',
    gridProfilesChangeRobotSmsCredits: 'Change Robot SMS Credits',
    gridProfilesChangeRobotSmsCreditsCount: 'Robot SMS Credits:',
    gridProfilesChangeRobotSmsAccess: 'Robot SMS',
    gridProfilesRestoreUser: 'Restore user',

    buttonDownloadAllInformation: 'Download all information',
    profilesDeletedProfiles: 'Deleted profiles',
    profilesAllProfiles: 'All profiles',
    profilesTotalCreditsCountL: 'Robot Sms Total Credits:',

    //ALL EVENTS
    gridEventsOpenAsOwner: 'Open as owner',
    gridEventsPaymentStatus: 'Payment Status',
    gridEventsPaymentStatusIsPaidCheckBox: 'Paid',
    gridEventsOrderConfirmation: 'Order Confirmation',
    gridEventsFile: 'File',
    gridEventsReceiptFile: 'Receipt file',
    gridEventsInvoiceFile: 'Invoice file',
    gridEventsBankAccountInfo: 'Bank account info',
    gridEventsBankAccountInfoNumber: 'Branch number',
    gridEventsBankAccountInfoAccountNumber: 'Account number',
    gridEventsBankAccountFullName: 'Full name',
    gridEventsTotalCommisionProfit: 'Total commission profit',
    gridEventsTotalTicketsPurchasedInCredit: 'Total money without fee',
    gridEventsTotalTicketsSold: 'Total tickets sold',
    gridEventsTotalTicketsSoldInCredit: 'Total tickets sold in credit',
    gridEventsCanceledMoney: 'Canceled money',
    gridEventsCanceledCount: 'Canceled count',
    gridEventsReturnedMoney: 'Returned money',
    gridEventsReturnedCount: 'Returned count',
    gridEventsProducerAndIdentityCardOrHPAndPhoneNumber: 'Producer info',
    gridEventsCreatorName: 'Full name',
    gridEventsCreatorAddress: 'Address',
    gridEventsCreatorPhone: 'Phone number',
    gridEventsStartDate: 'Date of start',
    gridEventsCreateDate: 'Date of creation',
    gridEventsName: 'Event name',
    gridEventsStatus: 'Event status',
    gridEventsStatusDeleted: 'DELETED',
    gridEventsStatusHidden: 'HIDDEN',
    gridEventsRepeatEvent: 'REPEAT EVENT',
    gridEventsShowEvent: 'SHOW EVENT',
    gridEventsConfirmEvent: 'CONFIRM EVENT',
    gridEventsDeleteEvent: 'DELETE EVENT',

    //TRANSACTIONS
    invoiceTotalInvoicesBeforeVAT: 'Total invoices before VAT',
    invoiceTotalVAT: 'Total VAT',
    invoiceTotalInvoicesIncluded: 'Total invoices included',
    invoiceThisEventHasNoSuchFiles: 'This event has no such files',
    transactionsInvoices: 'Invoices',
    transactionsConfirm: 'Confirms',
    transactionsCancelled: 'Canceled',
    transactionsFetchError: 'An error occurred while loading the page',

    //LOGIN
    loginEnterNumber: 'ENTER YOUR PHONE NUMBER HERE',
    loginSendCode: 'SEND CODE VIA SMS',
    loginEnterCode: 'ENTER YOUR OTP CODE HERE',
    loginVerifyCode: 'VERIFY OTP CODE',
    loginCodeSendSuccess: 'OTP sended successfully!',
    loginCodeInvalid: 'Invalid code!',
    loginError: 'ERROR!',
    
    // Other
    changeUserCredits: 'Update user robot sms credits',
    updateCreditsError: 'Error. Try again please.'

    // loginEnterNumber: 'ENTER YOUR PHONE NUMBER HERE',
    // loginEnterNumber: 'ENTER YOUR PHONE NUMBER HERE',
  }
}
