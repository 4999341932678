import Modal from "../../Unknown/Modal";
import { useAppDispatch, useAppSelector } from "../../../modules/store/hooks";
import { modalsSlice } from "../../../modules/store/reducers/modalsSlice";
import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import translate from "../../../i18n/translate";

export default function RobotSmsCreditsModal({ handleChange, robotSmsCredits } : { handleChange: (credits: number) => void, robotSmsCredits: number | null }) {
  const dispatch = useAppDispatch();
  const [credits, setCredits] = useState(robotSmsCredits || 0)
  const { toggleRobotSmsCreditsModal } = modalsSlice.actions;
  const visible = useAppSelector((state) => state.modalsReducer.robotSmsCredits);

  const handleSubmit = () => {
    handleChange(credits);

    dispatch(toggleRobotSmsCreditsModal());
  };

  const onCloseModal = () => {
    dispatch(toggleRobotSmsCreditsModal());
  }

  return (
    <Modal
      title={translate("robotCreditsModalTitle")}
      visible={visible === "open"}
      style={{ maxWidth: "500px" }}
      setVisible={onCloseModal}
    >
      <Box my={2} p={2} display="flex" flexDirection="column" justifyContent="center" width={1} alignItems="center" gap={2}>
        <TextField 
            variant='outlined'
            fullWidth
            value={credits}
            type='number'
            onChange={(e) => setCredits(Number(e.target.value))}
            dir="ltr"
            sx={{
              paddingRight: "20px",
              '.MuiInputBase-root.MuiInput-root:before':{
                borderBottom: "0px"
              },
              '.MuiInputBase-root.MuiInput-root:hover':{
                borderBottom: "0px"
              },
            }}
            />
        <Button
          size="large"
          variant="contained"
          color="warning"
          fullWidth
          onClick={handleSubmit}
          disabled={credits < 0}
          >
          {translate("changeUserCredits")}
        </Button>
      </Box>
    </Modal>
  );
}
