import { createSlice } from '@reduxjs/toolkit'

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    pdfViewer: 'close',
    activeDevices: 'close',
    sendOtp: 'close',
    addPayment: 'close',
    robotSmsCredits: 'close',
  },
  reducers: {
    toggleActiveDevicesModal(state) {
      return { ...state, activeDevices: state.activeDevices === 'open' ? 'close' : 'open' }
    },
    toggleSendOtpModal(state) {
      return { ...state, sendOtp: state.sendOtp === 'open' ? 'close' : 'open' }
    },
    toggleAddPaymentModal(state) {
      return { ...state, addPayment: state.addPayment === 'open' ? 'close' : 'open' }
    },
    togglePdfViewerModal(state) {
      return { ...state, pdfViewer: state.pdfViewer === 'open' ? 'close' : 'open' }
    },
    toggleRobotSmsCreditsModal(state) {
      return { ...state, robotSmsCredits: state.robotSmsCredits === 'open' ? 'close' : 'open' }
    },
  },
})

export default modalsSlice.reducer
